


















































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import AbstractLiveSessionCommentView from "../common/AbstractLiveSessionCommentView.vue";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import FilterUtil from "@/utilities/FilterUtil";
import { SnackbarProperties } from "@/components/shared/SnackbarView.vue";
import DateUtil from "@/utilities/DateUtil";

@Component({})
export default class LiveSessionStreamerCommentView extends AbstractLiveSessionCommentView {
    private lastFilter: any = {};

    public created() {
        window.addEventListener("resize", this.onAdjustHeight);
        this.properties.events.subscribe("load-comments", this.load);
    }

    public destroyed() {
        window.removeEventListener("resize", this.onAdjustHeight);
		this.properties.events.remove("load-comments", this.load);
    }

    public async mounted() {
		this.footer = document.getElementById("footer");
        this.sender = this.$el.querySelector("#comment-sender");
        this.container = this.$el.querySelector("#comment-container");
        this.control = this.$el.querySelector("#comment-page-control");

        this.onAdjustHeight();
        await this.load(true);
    }

    public onAdjustHeight() {
        this.titleHeight = this.$vuetify.breakpoint.lgAndUp ? 68 : 0;
        this.adjustHeight();
    }
	
    @Watch("properties.liveSession.status")
    public async load(force: boolean = false, reschedule: boolean = true) {
        this.$data.loading = true;
        try {
            if (force || this.$data.reload) {
				//const scrollPercent = ElementUtil.getScrollPercent(this.container);
				const liveSessionId = this.liveSession?.id ?? 0;
                if (liveSessionId !== 0) {
                    const lastId = this.comments[this.comments.length - 1]?.id;
                    const commentDate =  FilterUtil.filterDate(
                        false, 
                        this.liveSessionCommentSearch.dateFrom, 
                        this.liveSessionCommentSearch.dateTo
                    );
                    const search = this.liveSessionCommentSearch.build();
                    const excludeAccepted = this.liveSessionCommentSearch.excludeAccepted;

                    let reloadAll = false;
                    if (this.lastFilter.commentDate !== commentDate ||
                        this.lastFilter.search !== search ||
                        this.lastFilter.excludeAccepted !== excludeAccepted)    
                    {
                        reloadAll = true;
                    }

                    const r = await this.facebookCommentService.get({
                        id: (lastId && !reloadAll) ? `>${lastId}` : null,
                        liveSessionId,
						commentDate,
                        search,
                        loadColors: true,
						excludeAccepted
                    });

                    this.lastFilter = {
                        commentDate,
                        search,
                        excludeAccepted
                    };

                    let comments: any[] = r.data.facebookComments;
                    if (comments.length > 0) {
                        comments = comments.map(e => {
                            e.changed = e.fullNameChanged;
                            return e;
                        }).sort((lhs, rhs) => lhs.id - rhs.id);
                        if (reloadAll)
                            this.comments = comments;
                        else
                            this.comments.push(...comments);
                    }
					//this.container.scrollTop = ElementUtil.getScrollTop(this.container, scrollPercent);
                }
            }
        } catch (e) {
            SnackbarProperties.reset(this.snackbar);
            this.snackbar.timeout = 3000;
            this.snackbar.alignment = "bottom-right";
            this.snackbar.message = this.$t("message.load-comment-failed");
            this.snackbar.visible = true;
        } finally {
            if (this.timerEnabled && reschedule && this.liveSession.status === "Started") {
                if (this.handle !== 0) clearTimeout(this.handle);
                this.handle = setTimeout(this.load, 1500);
            }
        }
    }

    public async updateStreamerReaded(comment: any) {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            await this.facebookCommentService.put({
                id: comment.id,
                version: comment.version,
                values: {
                    streamerReaded: comment.streamerReaded.toString(),
                },
            });

            if (this.liveSession.status !== "Started") {
                await this.load();
            }
        } catch (e) {
            if (!(await ExceptionUtil.isAuthError(this.$router, e))) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }
    
    public formatCommentDate(comment: any) {
        if (!comment.commentDate) return "";

        const v = new Date(comment.commentDate);
        const s = DateUtil.between(new Date(), v, "s");
        
        const secs = [5, 10, 15, 30, 60];
        for (var i = 0; i < secs.length; i++) {
            if (s < secs[i]) return this.$t("message.live-session-comment-date1", [secs[i].toString()]);
        }        

        const mins = [5, 10, 15, 30, 60];
        for (var i = 0; i < secs.length; i++) {
            if (s < mins[i]) return this.$t("message.live-session-comment-date2", [mins[i].toString()]);
        }     
        
        const formattedDate = DateUtil.format(v, this.properties.dateFormat);
        const formattedTime = DateUtil.format(v, this.properties.timeFormat);

        return `${formattedDate}\n${formattedTime}`;
    }

	public commentDialog(comment: any) {
        if (!comment.facebookAccountId || comment.page) {
            return;
        }

        this.liveSessionCommentDialog.liveSessionId = this.liveSession.id;
        this.liveSessionCommentDialog.facebookCommentId = comment.id;
        this.liveSessionCommentDialog.visible = true;
    }
}
