




















































import { Vue, Component } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { SnackbarProperties } from "@/components/shared/SnackbarView.vue";
import { LiveSessionCommentDialogProperties } from "../common/LiveSessionCommentDialogView.vue";
import { LiveSessionCommentSearchProperties } from "../common/LiveSessionCommentSearchView.vue";
import { LiveSessionCommentNameHistoryProperties } from "../common/LiveSessionCommentNameHistoryView.vue";
import { LiveSessionInfoProperties } from "../common/CommonLiveSessionProperties";
import LiveSessionStreamerCommentView from "./LiveSessionStreamerCommentView.vue";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import ResponseUtil from "@/utilities/ResponseUtil";
import LiveSessionService from "@/services/LiveSessionService";

const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");
const SnackbarView = () => import("@/components/shared/SnackbarView.vue");
const LiveSessionCommentDialogView = () => import("../common/LiveSessionCommentDialogView.vue");
const LiveSessionCommentSearchView = () => import("../common/LiveSessionCommentSearchView.vue");
const LiveSessionCommentNameHistoryView = () => import("../common/LiveSessionCommentNameHistoryView.vue");

@Component({
    metaInfo: {
        link: [{ rel: "stylesheet", href: "/live-session-info.css" }],
    },
    components: {
        AlertDialogView,
        SnackbarView,
		LiveSessionCommentDialogView,
		LiveSessionCommentSearchView,
        LiveSessionStreamerCommentView,
		LiveSessionCommentNameHistoryView
    },
    data: () => ({
        saving: false,
    }),
})
export default class LiveSessionStreamerView extends Vue {
    private properties = new LiveSessionInfoProperties();
    private alertDialog = new AlertDialogProperties();
    private snackbar = new SnackbarProperties();
	private liveSessionCommentDialog = new LiveSessionCommentDialogProperties();
	private liveSessionCommentSearch = new LiveSessionCommentSearchProperties();
	private liveSessionCommentNameHistory = new LiveSessionCommentNameHistoryProperties();
    private liveSessionService = new LiveSessionService();

    public get title() {
        const tmp1 = this.$t("title.live-session");
        const status: string = this.liveSession?.status ?? "";
		if (status === "") return tmp1;

        const tmp2 = this.$t("text." + status.toLowerCase());
        const tmp3 = this.$t("text.streamer");
        const tmp4 = this.liveSession?.uniqueNo;

        const bracket = tmp4 ? `${tmp4}, ${tmp3}` : tmp3;

        return `${tmp1} - ${tmp2} (${bracket})`;
    }

    public get liveSession() {
        return this.properties.liveSession;
    }

    public created() {
        this.htmlOverflowY();
    }

    public async mounted() {
        await this.load();
    }

    public destroyed() {
        document.body.parentElement.style.overflowY = "";
    }

    public async htmlOverflowY() {
        //required for firefox to hide scrollbar properly
        var html = document.body.parentElement;
        html.style.overflowY = "hidden";
        await Vue.nextTick();
        html.style.overflowY = "auto";
    }

    public async load() {
        try {
            var id = this.$route.query.id;
            const r = await this.liveSessionService.get({ id });
            if (r.data.liveSessions.length === 0) {
                throw new Error(this.$t("message.record-not-found").toString());
            }
            const liveSession = r.data.liveSessions[0];

            this.properties.dateFormat = ResponseUtil.getDateFormat(r);
            this.properties.timeFormat = ResponseUtil.getTimeFormat(r);
            this.properties.dateTimeFormat = ResponseUtil.getDateTimeFormat(r);
            this.properties.liveSession = liveSession;
            if (liveSession.status === "Preparing") {
                this.properties.visibleData = true;
            }

            await this.properties.events.fire("loaded");
        } catch (e) {
            if (!(await ExceptionUtil.isAuthError(this.$router, e))) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.tag = { command: "load-error" };
                this.alertDialog.visible = true;
            }
        }
    }

    public async alertDialogClicked(button: string) {
        this.alertDialog.visible = false;
        await this.alertDialogClosed();
    }

    public async alertDialogClosed() {
        if (this.alertDialog.visible) {
            return;
        }

        const command = this.alertDialog.tag?.command;
        if (command === "invalid-mode" || command === "load-error" || command === "record-deleted") {
        	await this.$router.push("/management/live-sessions/streamer");
        }
    }
}
